.clearfix, footer, .tags { *zoom: 1; }
.clearfix:before, footer:before, .tags:before, .clearfix:after, footer:after, .tags:after { display: table; content: ""; line-height: 0; }
.clearfix:after, footer:after, .tags:after { clear: both; }

/** Syntax highlighting styles */
.highlight { margin-bottom: 20px; }
.highlight code { display: block; padding: 20px; line-height: 1.5; overflow: auto; }
.highlight .c { color: #998; font-style: italic; }
.highlight .err { color: #a61717; background-color: #e3d2d2; }
.highlight .k { font-weight: bold; }
.highlight .o { font-weight: bold; }
.highlight .cm { color: #998; font-style: italic; }
.highlight .cp { color: #999; font-weight: bold; }
.highlight .c1 { color: #998; font-style: italic; }
.highlight .cs { color: #999; font-weight: bold; font-style: italic; }
.highlight .gd { color: #000; background-color: #fdd; }
.highlight .gd .x { color: #000; background-color: #faa; }
.highlight .ge { font-style: italic; }
.highlight .gr { color: #a00; }
.highlight .gh { color: #999; }
.highlight .gi { color: #000; background-color: #dfd; }
.highlight .gi .x { color: #000; background-color: #afa; }
.highlight .go { color: #888; }
.highlight .gp { color: #555; }
.highlight .gs { font-weight: bold; }
.highlight .gu { color: #aaa; }
.highlight .gt { color: #a00; }
.highlight .kc { font-weight: bold; }
.highlight .kd { font-weight: bold; }
.highlight .kp { font-weight: bold; }
.highlight .kr { font-weight: bold; }
.highlight .kt { color: #458; font-weight: bold; }
.highlight .m { color: #099; }
.highlight .s { color: #d14; }
.highlight .na { color: #008080; }
.highlight .nb { color: #0086B3; }
.highlight .nc { color: #458; font-weight: bold; }
.highlight .no { color: #008080; }
.highlight .ni { color: #800080; }
.highlight .ne { color: #900; font-weight: bold; }
.highlight .nf { color: #900; font-weight: bold; }
.highlight .nn { color: #555; }
.highlight .nt { color: #000080; }
.highlight .nv { color: #008080; }
.highlight .ow { font-weight: bold; }
.highlight .w { color: #bbb; }
.highlight .mf { color: #099; }
.highlight .mh { color: #099; }
.highlight .mi { color: #099; }
.highlight .mo { color: #099; }
.highlight .sb { color: #d14; }
.highlight .sc { color: #d14; }
.highlight .sd { color: #d14; }
.highlight .s2 { color: #d14; }
.highlight .se { color: #d14; }
.highlight .sh { color: #d14; }
.highlight .si { color: #d14; }
.highlight .sx { color: #d14; }
.highlight .sr { color: #009926; }
.highlight .s1 { color: #d14; }
.highlight .ss { color: #990073; }
.highlight .bp { color: #999; }
.highlight .vc { color: #008080; }
.highlight .vg { color: #008080; }
.highlight .vi { color: #008080; }
.highlight .il { color: #099; }

section { position: relative; }

.contained { width: 100%; max-width: 37.5em; }

.centered { margin-left: auto; margin-right: auto; }

.viewport-height { min-height: 400px; height: 100vh; }

.viewport-half-height { min-height: 200px; height: 50vh; }

.valign-center { display: flex; align-items: center; }

.hero { background-size: 100% 100%; }

.gray-bg { background-color: #676564; color: #333; }

.light-gray-bg { background-color: #dddcdb; color: #333; }

.blue-bg { background-color: #304dbb; color: #fff; }

.white { color: #fff; }

.blue { color: #304dbb; }

.gray { color: #676564; }

.nameplate, .large-nameplate { display: inline-block; }
.nameplate img, .large-nameplate img { display: block; width: 160px; }
.nameplate.underlined, .underlined.large-nameplate { padding-left: .75em; padding-right: .75em; padding-bottom: .375em; border-bottom: solid 1px; }
.nameplate.bordered, .bordered.large-nameplate { border: solid 2px; border-radius: 3px; padding: .75em .75em .5em .75em; }
.nameplate a, .large-nameplate a { color: inherit; }
.nameplate a:hover, .large-nameplate a:hover { color: inherit; }

.nav { position: absolute; top: 0; left: 0; right: 0; padding: 20px 40px; }

.bg-image { background-size: cover; }

.padded { padding: 30px; }
@media (min-width: 30em) { .padded { padding: 40px; } }

.float-left { float: left; }

.float-right { float: right; }

.float-none { float: none; }

.flex { display: flex; }
.flex.columns { flex-direction: column; }

.flex-grow { flex-grow: 1; }

h1, h2, h3, h4, h5, h6 { margin-top: 20px; margin-bottom: 20px; line-height: 1.25; }
h1.underlined, h2.underlined, h3.underlined, h4.underlined, h5.underlined, h6.underlined { border-bottom: solid 1px #dddcdb; padding-bottom: .5em; }
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child { margin-top: 0; }
h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child { margin-bottom: 0; }

h1 { font-size: 1.875em; margin-bottom: 40px; }
@media (min-width: 30em) { h1 { font-size: 2.5em; } }

h2 { font-size: 1.5em; padding-bottom: .25em; border-bottom: solid 1px #dddcdb; }
@media (min-width: 30em) { h2 { font-size: 1.875em; } }

h3 { font-size: 1.25em; }
@media (min-width: 30em) { h3 { font-size: 1.5em; } }

h4 { font-size: 1.25em; }

a { text-decoration: none; color: #555; -webkit-transition: color .3s; transition: color .3s; }
a:hover { color: #333; }
a.white:hover { color: #fff; }

p { font-family: 'Merriweather', serif; line-height: 1.75; color: #555; margin-top: 20px; margin-bottom: 20px; }
@media (min-width: 30em) { p { line-height: 1.875; } }
p a { color: #2ec97b; border-bottom: dotted 1px #2ec97b; }
p a:hover { color: #2ec97b; border-bottom-style: solid; }
p:first-child { margin-top: 0; }
p:last-child { margin-bottom: 0; }

time { color: #676564; font-size: .875em; font-family: 'Merriweather', serif; font-style: italic; }

.align-center { text-align: center; }

.align-left { text-align: left; }

.align-right { text-align: right; }

.transform-uppercase { text-transform: uppercase; }

code { padding: 3px 5px; background-color: #f6f5f4; color: #555; margin: 0; line-height: 1; border-radius: 2px; font-size: .875em; display: inline-block; }

*, *:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

html { -webkit-text-size-adjust: 100%; }

body { margin: 0; padding: 0; width: 100%; height: 100%; background-color: #fefdfc; color: #333; font-size: 16px; font-family: 'Open Sans', Helvetica, sans-serif; }
@media (min-width: 30em) { body { font-size: 18px; } }

footer { padding: 20px 40px; background-color: #333; color: #676564; }
footer a { color: #676564; }
footer a:hover { color: #dddcdb; }

.posts { border-left: solid 2px #2ec97b; padding-left: 30px; padding-top: 20px; padding-bottom: 20px; }
@media (min-width: 30em) { .posts { padding-left: 40px; } }
.posts h2 { margin-bottom: 30px; }

.post { border-bottom: solid 1px #dddcdb; padding-bottom: 40px; margin-bottom: 30px; position: relative; }
.post:last-child { margin-bottom: 0; border-bottom: none; padding-bottom: 0; }
.post:before { content: ''; display: block; position: absolute; left: -41px; top: 3px; height: 20px; width: 20px; border: solid 2px #2ec97b; background-color: #fefdfc; border-radius: 100%; -webkit-transition: background-color .3s; transition: background-color .3s; }
@media (min-width: 30em) { .post:before { left: -51px; } }
.post:hover:before { background-color: #2ec97b; }

.tags { margin-top: 20px; }

.tag { background-color: #2ec97b; color: #fff; font-size: .75em; padding: .25em .5em; display: inline-block; margin-right: 8px; vertical-align: top; }
.tag a { color: #fff; text-decoration: none; }

@keyframes nameplate-in { 0% { opacity: 0; -webkit-transform: translateY(-10px); transform: translateY(-10px); }
  20% { opacity: 0; -webkit-transform: translateY(-10px); transform: translateY(-10px); }
  100% { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px); } }
@keyframes blink { from { opacity: 1; }
  to { opacity: 0; } }
.fake-form { max-width: 260px; margin-left: auto; margin-right: auto; }
.fake-form.hide { display: none; }

.fake-form-row { margin-bottom: 10px; width: 100%; }

.fake-label { color: #fff; margin-bottom: 5px; font-size: .75em; }

.fake-input { background-color: #fff; color: #555; padding: 1em; text-align: left; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.125); border-radius: 3px; transition: box-shadow .3s; }
.fake-input.focus { box-shadow: 0 2px 5px rgba(0, 0, 0, 0.375); }
.fake-input.focus .cursor { visibility: visible; }

.fake-submit { background-color: #2ec97b; padding: 1em; margin-top: 30px; color: #fff; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.125); text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25); text-transform: uppercase; text-align: center; border-radius: 3px; font-weight: bold; position: relative; top: 0; transition: top .2s, box-shadow .2s, background-color .2s; }
.fake-submit.hover { background-color: #50D794; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25); }
.fake-submit.click { top: 2px; box-shadow: none; }

.cursor { position: relative; visibility: hidden; }
.cursor:before { content: ''; position: absolute; height: 100%; width: 1px; background-color: #555; margin-left: 2px; animation: blink 1.125s steps(2, start) infinite; }

.large-nameplate { display: block; padding-bottom: 0; margin-bottom: 0; opacity: 1; }
.large-nameplate img { display: block; width: 100%; }

.name { animation: nameplate-in 2s 1; }
.name.hide { display: none; }
.name .nameplate-title { color: #fff; text-transform: uppercase; font-size: .875em; letter-spacing: .625em; position: relative; }
@media (min-width: 30em) { .name .nameplate-title { font-size: .875em; letter-spacing: 1.125em; left: .5em; } }
@media (min-width: 48em) { .name .nameplate-title { font-size: 1em; left: .5em; letter-spacing: 1.625em; } }

.scrolldown { text-align: center; color: #fff; font-size: .75em; padding: 20px; cursor: pointer; }
